<template>
  <div class="login-container" :style="containerstyle">
    <el-row>
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="12">
        <div class="leftimgbox">
          <img alt="" :src="leftimgsrc" />
        </div>
      </el-col>
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="12">
        <div class="login-form-box">
          <img
            alt=""
            class="login-form-img"
            src="@/assets/login_images/loginform1.png"
          />
          <div ref="formRef" class="login-form">
            <div v-show="logintype == 'dma'">
              <dma />
            </div>
            <div v-if="logintype == 'wx'">
              <div class="title-tips">
                请在微信后台管理中登录后点击右上角扫一扫登录
              </div>
              <div class="qrbox">
                <vue-qr
                  class="pointer"
                  :correct-level="0"
                  :logo-corner-radius="0"
                  :margin="0"
                  :size="260"
                  :text="ddh"
                />
              </div>
            </div>
            <div v-if="logintype == 'phone'">
              <phone />
            </div>
            <div
              style="
                margin: 26px 0 26px 0;
                font-size: 16px;
                color: #aaaaaa;
                text-align: center;
              "
            >
              其他方式登录
            </div>
            <div class="loginlist">
              <div
                v-if="logintype != 'dma'"
                class="longintypeicon"
                @click="logintype = 'dma'"
              >
                <img
                  alt=""
                  class="loginimg"
                  src="@/assets/login_images/login2.png"
                />
              </div>
              <div
                v-if="logintype != 'wx'"
                class="longintypeicon"
                @click="logintype = 'wx'"
              >
                <img
                  alt=""
                  class="loginimg"
                  src="@/assets/login_images/login3.png"
                />
              </div>
              <div
                v-if="logintype != 'phone'"
                class="longintypeicon"
                @click="logintype = 'phone'"
              >
                <img
                  alt=""
                  class="loginimg"
                  src="@/assets/login_images/login1.png"
                />
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="bqsy">
      <a href="https://beian.miit.gov.cn/" style="color: #fff" target="_blank">
        版权所有 浙ICP备10216477号-2
      </a>
    </div>
  </div>
</template>

<script>
  // import { useSettingsStore } from '@/store/modules/settings'
  import randomString from '@/z/callback/randomString'
  import request from '@/z/callback/request'
  import vueQr from 'vue-qr/src/packages/vue-qr.vue'
  import dma from './components/dma.vue'
  import phone from './components/phone.vue'
  import { reactive, nextTick, watchEffect, watch, toRefs } from 'vue'

  import { useRoute, useRouter } from 'vue-router'
  console.log(DataURLimg)
  export default {
    name: 'Login',
    components: {
      dma,
      vueQr,
      phone,
    },
    directives: {
      focus: {
        mounted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    setup() {
      const route = useRoute()
      const router = useRouter()

      // const settingsStore = useSettingsStore()

      const state = reactive({
        containerstyle: {
          background: `url('${DataURLimg}/index/bj.jpg') center center fixed no-repeat`,
        },
        leftimgsrc: `${DataURLimg}/logo.png`,
        ddh: 'cdzlogin:' + randomString(),
        logintype: 'dma',
        jzmm: true,
        formRef: null,
        passwordRef: null,
        form: {
          uUser: '',
          dma: '',
        },

        loading: false,
        passwordType: 'password',
        redirect: undefined,

        previewText: '',
      })

      const handlePassword = () => {
        state.passwordType === 'password'
          ? (state.passwordType = '')
          : (state.passwordType = 'password')
        nextTick(() => {
          console.log(state)
          state['passwordRef'].focus()
        })
      }

      watchEffect(() => {
        state.redirect = (route.query && route.query.redirect) || '/'
      })

      async function LoginScanCodeSearchcallback() {
        let par = await request('/LoginScanCodeSearch', {
          ddh: state.ddh,
        })
        if (!par || par[0].par != 'succeed') {
          setTimeout(() => {
            if (state.logintype == 'wx') {
              LoginScanCodeSearchcallback()
            }
          }, 2000)
        } else {
          router.push('/index')
        }
      }
      watch(
        () => state.logintype,
        (n) => {
          if (n == 'wx') {
            LoginScanCodeSearchcallback()
          }
        }
      )

      return {
        ...toRefs(state),
        // title: settingsStore.getTitle,
        handlePassword,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bqsy {
    position: fixed;
    right: 40px;
    bottom: 20px;
    color: #fff;
  }
  .qrbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leftimgbox {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    img {
      width: 80%;
      max-width: 570px;
    }
  }
  .loginlist {
    display: flex;
    justify-content: center;
    padding-bottom: 26px;
    .longintypeicon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      margin: 0 10px;
      font-size: 42px;
      cursor: pointer;
      &::before {
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        content: ' ';
        background: #fff;
        border-radius: 50%;
      }
      .loginimg {
        z-index: 10;
        width: 42px;
        height: 42px;
      }
    }
  }
  .login-container {
    height: 100vh;
    background: url('~@/assets/login_images/background.jpg') center center fixed
      no-repeat;
    background-position: center top;
    background-size: cover;
  }
  .login-form-box {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: calc((100vh - 555px) / 2);
    .login-form-img {
      width: 100%;
      height: auto;
      // margin-bottom: 46px;
    }
    .login-form {
      position: relative;
      width: 100%;
      padding: 36px 60px 0 60px;

      overflow: hidden;
      background: rgba(0, 0, 0, 0);
      background-image: url('~@/assets/login_images/loginform2.png');
      background-size: 100% 100%;

      // border: 2px solid #037cff;
      .title {
        font-size: 54px;
        font-weight: 500;
        color: var(--el-color-white);
      }

      .title-tips {
        // margin-bottom: 38px;
        font-size: 20px;
        line-height: 40px;
        font-weight: bold;
        font-weight: 400;
        color: var(--el-color-white);
        text-align: center;
      }

      .login-btn {
        display: inherit;
        width: 100%;
        height: 56px;
        margin-top: 5px;
        font-size: 20px;
        background-image: linear-gradient(#0296fb, #0b6ff9);
        border: 0;
        &:hover {
          opacity: 0.9;
        }
      }

      .tips {
        margin-bottom: 10px;
        font-size: 14px;
        color: var(--el-color-white);

        span {
          &:first-of-type {
            margin-right: 16px;
          }
        }
      }

      .title-container {
        position: relative;

        .title {
          margin: 0 auto 40px auto;
          font-size: 34px;
          font-weight: bold;
          color: var(--el-color-primary);
          text-align: center;
        }
      }

      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        font-size: 20px;
        color: #0296fb;
      }

      .show-password {
        float: right;
        width: 32px;
        height: 32px;
        font-size: 16px;
      }

      :deep() {
        .el-form-item {
          padding-right: 0;
          margin: 26px 0;
          color: #454545;
          background: transparent;
          border: 1px solid transparent;
          border-radius: 2px;

          &__content {
            // min-height: $base-input-height;
            // line-height: $base-input-height;
          }

          &__error {
            position: absolute;
            top: 100%;
            left: 18px;
            // font-size: $base-font-size-small;
            line-height: 18px;
            color: var(--el-color-error);
          }
        }

        .el-input {
          // --el-input-border-color: #131313;
          box-sizing: border-box;
          // background: hsla(215, 95%, 51%, 0.2);
          border-radius: 4px;
          box-shadow: 0 0 0 1px
            var(--el-input-border-color, var(--el-border-color)) inset;
          .el-input__wrapper {
            background: hsla(215, 95%, 51%, 0.2);
          }
          input {
            height: 56px;
            padding-left: 50px;
            line-height: 48px;
            color: #fff;
            border: 0;
          }

          &__suffix-inner {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -100%;
            cursor: pointer;
          }
        }

        .code {
          position: absolute;
          top: 4px;
          right: 4px;
          cursor: pointer;
          // border-radius: $base-border-radius;
        }
      }
    }
  }
</style>
