<template>
  <el-form
    ref="formRef"
    class="login-formdma"
    label-position="left"
    :model="form"
    :rules="rules"
  >
    <!-- <div ref="titleaaa" class="title-tips" @click="handlePassword">
      手机号登录
    </div> -->

    <el-scrollbar v-if="uteluser.length != 0" height="200px">
      <div
        v-for="(item, key) in uteluser"
        :key="key"
        class="uuserbtn"
        size="large"
        @click="handleLogin(item.uUser)"
      >
        {{ item.uUser }}
      </div>
    </el-scrollbar>
    <div v-else>
      <el-form-item prop="utel">
        <el-input
          v-model.trim="form.utel"
          v-focus
          placeholder="请输入手机号"
          tabindex="1"
          type="text"
        >
          <template #prefix>
            <i class="iconfont icon-icon-gerenzhongxin"></i>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="res" style="display: flex">
        <el-input
          v-model.trim="form.res"
          v-focus
          placeholder="图形验证码"
          type="text"
        >
          <template #prefix>
            <i class="iconfont icon-icon2"></i>
          </template>
        </el-input>
        <img
          alt=""
          class="phone-code"
          :src="Kaptcha.img"
          @click="getWpassSmsAreaKaptcha"
        />
      </el-form-item>
      <el-form-item prop="yzm" style="position: relative">
        <el-input
          :key="passwordType"
          ref="passwordRef"
          v-model.trim="form.yzm"
          placeholder="请输入验证码"
          tabindex="2"
          @keyup.enter="handleLogin"
        >
          <template #prefix>
            <i class="iconfont icon-icon2"></i>
          </template>
        </el-input>
        <el-button
          class="phone-code"
          :disabled="yzmbtnloading"
          type="primary"
          @click="getWpassSmsfn"
        >
          {{ yzmbtnstr }}
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          class="login-btn"
          :loading="loading"
          :style="loginbtnbg"
          type="primary"
          @click="handleLogin"
        >
          登录
        </el-button>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>
  const validateUsername = (rule, value, callback) => {
    if ('' === value) callback(new Error('手机号不能为空'))
    else callback()
  }
  const validatePassword = (rule, value, callback) => {
    if ('' === value) callback(new Error('验证码不能为空'))
    else callback()
  }
  // import { useUserStore } from '@/store/modules/user'
  // import qs from 'qs'
  import request from '@/z/callback/request'
  import { ElMessage } from 'element-plus'
  // let userStore = useUserStore()
  export default {
    name: 'Phone',
    directives: {
      focus: {
        mounted(el) {
          el.querySelector('input').focus()
        },
      },
    },

    data() {
      return {
        loginbtnbg: {
          width: '180px',
          margin: '0 auto',
          background: 'none',
          backgroundImage: ` url('${DataURLimg}/index/submit.png')`,
          backgroundSize: '100% 100%',
        },
        uteluser: [],
        yzmbtnloading: false,
        yzmbtnstr: '获取验证码',
        loading: false,
        passwordType: 'password',
        form: {
          utel: '',
          yzm: '',
          res: '',
        },
        rules: {
          utel: [
            {
              required: true,
              trigger: 'blur',
              validator: validateUsername,
            },
          ],
          yzm: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
        },
        Kaptcha: {},
      }
    },
    mounted() {
      this.getWpassSmsAreaKaptcha()
    },
    methods: {
      handlePassword() {
        this.passwordType === 'password'
          ? (this.passwordType = '')
          : (this.passwordType = 'password')
        // this.$refs['passwordRef'].focus()
      },
      async getWpassSmsAreaKaptcha() {
        let Kaptcha = await request('/WpassSmsAreaKaptcha')
        Kaptcha.img = 'data:image/png;base64,' + Kaptcha.img
        this.Kaptcha = Kaptcha
        console.log(Kaptcha)
      },
      async getWpassSmsfn() {
        if (this.yzmbtnloading) {
          return
        }
        if (this.form.res == '' || !this.form.res) {
          // this.$u.toast('请填写图形验证码')
          this.$baseMessage('请填写图形验证码', 'error')
          return
        }

        let yzm = await request('/WpassSmsAreaNoLogin', {
          utel: this.form.utel,
          area: 10,
          mda: this.Kaptcha.mda,
          res: this.form.res,
        })
        if (yzm[0].par != 'succeed') {
          this.$baseMessage('图形验证码错误', 'error')
          return
        }
        this.yzmbtnloading = true
        this.yzmstr = yzm[0].par
        this.yzmbtnstrfn(60)
      },
      yzmbtnstrfn(time = 60) {
        time--
        this.yzmbtnstr = `${time}s后重新获取`
        if (time == 0) {
          this.yzmbtnstr = '重新获取'
          this.yzmbtnloading = false
        } else {
          setTimeout(() => {
            this.yzmbtnstrfn(time)
          }, 1000)
        }
      },
      async handleLogin(user) {
        this.$refs['formRef'].validate(async (valid) => {
          if (valid) {
            let paryzm = await this.$z.request('/WpassSmsChk', {
              utel: this.form.utel,
              sms: this.form.yzm,
            })
            if (paryzm[0].par != 'succeed') {
              ElMessage('验证码错误')
              return Promise.reject()
            }
            // console.log(this.form.yzm != this.yzmstr)
            // if (this.form.yzm != this.yzmstr) {
            //   ElMessage('验证码错误')
            //   return
            // }
            let LoginSmsdata = {
              utel: this.form.utel,
              smsche: this.form.yzm,
            }
            if (user) {
              LoginSmsdata.uUser = user
            }
            let par = await request('/LoginSms', LoginSmsdata)
            if (par.length == 0) {
              ElMessage('此账号无用户信息')
            } else if (par.length == 1) {
              this.$router.push('/index')
            } else {
              this.$baseAlert(
                '该手机号码下有多个用户，请选择登录的用户',
                '温馨提示'
              )
              this.uteluser = par
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .uuserbtn {
    display: block;
    width: 100%;
    padding-left: 20px;
    margin: 0 0 10px 0;
    line-height: 50px;
    color: #fff;
    text-align: left;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .phone-code {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 120px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
  }
  .login-formdma {
    position: relative;

    .title {
      font-size: 54px;
      font-weight: 500;
      color: var(--el-color-white);
    }

    .title-tips {
      margin-bottom: 38px;
      font-size: 26px;
      font-weight: 400;
      color: var(--el-color-white);
      text-align: center;
    }

    .login-btn {
      display: inherit;
      width: 100%;
      height: 56px;
      margin-top: 5px;
      font-size: 20px;
      background-image: linear-gradient(#0296fb, #0b6ff9);
      border: 0;
      &:hover {
        opacity: 0.9;
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: 14px;
      color: var(--el-color-white);

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: var(--el-color-primary);
        text-align: center;
      }
    }

    i {
      position: absolute;
      top: 12px;
      left: 15px;
      font-size: 20px;
      color: #fff;
    }

    .show-password {
      float: right;
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
  }
</style>
